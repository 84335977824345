





















































































































import './scss/BasePopup.scss';
import '../../components/BaseComposite/scss/BaseComposite.scss';
import './scss/PopupAddDocument.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { IProject, IMarkKit } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { ICategory, IDocument, IPackPD } from '@store/modules/project-documents/Interfaces';

const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { userNamespace } from '@store/modules/user';

@Component({
    name: 'PopupAddDocument',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
        BaseComposite: () => import('@components/BaseComposite/BaseComposite.vue'),
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
    }
})

export default class PopupAddDocument extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        form: HTMLFormElement,
        fileInput: HTMLFormElement,
    }

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.Getter('projectMarkKits') projectMarkKits!: IMarkKit[];
    @userNamespace.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSDocuments.Getter('packPDList') packPDList!: IPackPD[];
    @NSDocuments.Getter('documents') documents!: IDocument[];
    @NSDocuments.Getter('categoryList') categoryListStore!: ICategory[];
    @NSDocuments.Getter('documentsWithoutRecursion') documentsWithoutRecursion!: IDocument[];
    @NSDocuments.Action(ProjectDocumentsActions.A_CREATE_DOCUMENT) sendFormData!: (data) => Promise<void>

    fields: any = {
        title: '',
        description: '',
        markKit: null,
        packPD: null,
        category: null,
        parent: null,
        files: [],
    };

    errors: any = {
        title: false,
        description: false,
        parent: false,
        file: false,
    };

    packPDId:string = '';
    realtimeValidation = false;
    btnDisabled = false;
    errorText: string = '';
    errorEmptyText: string = 'Вы не заполнили одно или несколько обязательных полей';
    errorDuplicateNameDocument: string = 'Документ с таким названием уже существует';

    @Watch('fields', { deep: true })
    onChangeFields() {
        this.checkValidation();
        this.checkChangePackPDId()
    }

    get categoryList() {
        if (this.userRightOnlyGSN) {
            return this.categoryListStore.filter(item => item.title === 'Предписание');
        }
        return this.categoryListStore.filter(item => item.title !== 'Предписание');
    }

    get fileFormat() {
        return this.fields.files && this.fields.files[0] ? this.fields.files[0].name.split('.')[this.fields.files[0].name.split('.').length-1] : '';
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get packPDPlaceholder() {
        if (this.fields.packPD) {
            return this.fields.packPD.title;
        }
        return 'Выберите комплект ПД';
    }

    get documentsIsFolder() {
        return this.documentsWithoutRecursion.filter(item => item.isFolder);
    }

    get sortedProjectMarkKits() {
        return this.projectMarkKits.sort((a, b) => {
            return a.title > b.title ? 1 : -1;
        });
    }

    get sortedPackPDList() {
        return this.packPDList.sort((a, b) => {
            return a.title > b.title ? 1 : -1;
        });
    }

    get appId() {
        return this.projectData ? this.projectData.id : '';
    }

    get categoryId() {
        return this.fields.category ? this.fields.category.id : '';
    }

    get markKitId() {
        return this.fields.markKit ? this.fields.markKit.id : '';
    }

    get parentId() {
        return this.fields.parent ? this.fields.parent.uuid : null;
    }

    get documentNamesWithoutDuplicate(): string[] {
        let tempObj = {};
        return this.documents
            .filter(({ title }) => (!tempObj[title] && (tempObj[title] = 1)))
            .map((doc: IDocument) => doc.title);
    }

    checkChangePackPDId() {
        this.packPDId = '';
        this.packPDId = this.fields.packPD ? this.fields.packPD.uuid : '';
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    sendForm() {
        this.validateFields();
        if (!this.errorExists) {
            this.btnDisabled = true;
            const formData = new FormData(this.$refs.form);
            if (this.fields.files[0]) {
                formData.append('file', this.fields.files[0], this.fields.files[0].name);
            }
            this.sendFormData(formData).then(() => {
                this.closePopup();
                this.fields.title = '';
                this.fields.description = '';
                this.fields.markKit = null;
                this.fields.packPD = null;
                this.fields.category = null;
                this.fields.parent = null;
                this.$refs.fileInput.resetFiles();

                this.errors.title = false;
                this.errors.description = false;
                this.errors.parent = false;
                this.errors.category = false;
                this.errors.file = false;

                this.realtimeValidation = false;

                this.$emit('added');
            }).finally(() => this.btnDisabled = false);
        }
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    validateFields() {
        this.errors.title = !this.fields.title;
        this.errors.category = !this.fields.category;
        this.errors.file = !this.fields.files.length;

        if (!this.errorExists) {
            this.validateNameDocument();
        } else {
            this.errorText = this.errorEmptyText;
        }

        this.realtimeValidation = true;
    }

    validateNameDocument() {
        if (this.documentNamesWithoutDuplicate.includes(this.fields.title)) {
            this.errors.title = true;
            this.errorText = this.errorDuplicateNameDocument;
        } else {
            this.errors.title = false;
            this.errorText = '';
        }
    }

    childrenIsFolder(folder) {
        return folder.children.filter(item => item.isFolder);
    }

    resetParent() {
        this.fields.parent = null;
    }

    setSelectedParent(e) {
        this.fields.parent = e;
    }

    onFileChange(e) {
        this.fields.files = e;
    }
}
